import React from 'react';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import FsLightbox from 'fslightbox-react';
import { TailSpin } from 'react-loader-spinner';

class DocumentReference extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.state = {
      imageData: [],
      toggler: false,
      selectedId: '',
      slide: 1,
    };
  }

  loadData() {
    const client = this.context.client;

    this.props.ids.map(async id => {
      await client
        .request(
          {
            url: `${id}/$binary-access-read?path=DocumentReference.content.attachment`,
            federatedServerUrl: this.props.externalServerUrl,
          },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(data => data.blob())
        .then(async blob => {
          let imageB64 = await this.blobToBase64(blob);
          await client
            .request({ url: `DocumentReference?relatesto=${id}`, federatedServerUrl: this.props.externalServerUrl })
            .then(linkedFullResDocRef => {
              let linkedDocRefId = '';
              if (linkedFullResDocRef.entry) {
                linkedDocRefId =
                  linkedFullResDocRef.entry[0].resource.resourceType + '/' + linkedFullResDocRef.entry[0].resource.id;
              }
              let isVideo = imageB64.includes('video');
              this.setState({
                imageData: [
                  ...this.state.imageData,
                  {
                    linkedDocRefId: linkedDocRefId,
                    data: imageB64,
                    id: id,
                    fullRes: null,
                    isVideo: isVideo,
                  },
                ],
              });
            });
        });
    });
  }

  blobToBase64 = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  loadFullResPicture = async imageIndex => {
    const client = this.context.client;
    let imageArray = [...this.state.imageData];
    let imageId = imageArray[imageIndex].linkedDocRefId;

    if (imageId !== '') {
      await client
        .request({
          url: `${imageId}/$binary-access-read?path=DocumentReference.content.attachment`,
          federatedServerUrl: this.props.externalServerUrl,
        })
        .then(data => {
          if (data) {
            return data.blob();
          } else return;
        })
        .then(async blob => {
          let fullResB64 = await this.blobToBase64(blob);
          imageArray[imageIndex].fullRes = fullResB64;
          this.setState({ imageData: imageArray });
        })
        .catch(err => console.log(err));
    }
  };

  componentWillUnmount() {}

  componentDidMount() {
    this.loadData();
  }

  render() {
    const lightbox = (
      <FsLightbox
        toggler={this.state.toggler}
        sources={this.state.imageData.map(
          image =>
            (image = image.isVideo ? (
              <video src={image.fullRes ?? image.data} controls />
            ) : (
              <img src={image.fullRes ?? image.data} />
            ))
        )}
        thumbs={this.state.imageData.map(image => image.data)}
        slide={this.state.slide}
        key={this.props.sr_id}
        showThumbsOnMount={true}
        onSlideChange={slide => this.loadFullResPicture(slide.stageIndexes.current)}
      />
    );

    const selectImage = async imageId => {
      let imageIndex = this.state.imageData.findIndex(o => o.id == imageId);
      await this.loadFullResPicture(imageIndex);
      this.setState({ toggler: !this.state.toggler, selectedId: imageId, slide: imageIndex + 1 });
    };

    return (
      <div className="mediaElement" style={{ display: 'flex', flexDirection: 'row' }}>
        {this.props.ids.map((id, index) => (
          <div key={index}>
            {this.state.imageData[index] ? (
              <img
                src={this.state.imageData[index].data}
                width="100px"
                height="100px"
                style={{ paddingRight: '.5em' }}
                onClick={() => selectImage(this.state.imageData[index].id)}
              />
            ) : (
              <div
                style={{
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingRight: '.5em',
                }}
              >
                <TailSpin
                  ariaLabel="loading-indicator"
                  color="#21264e"
                  height="20"
                  width="20"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                />
              </div>
            )}
          </div>
        ))}
        {lightbox}
      </div>
    );
  }
}

export default withTranslation()(DocumentReference);
