import React from 'react';
import '../../css/views/CaseDetail.scss';
import { FhirClientContext } from '../../FhirClientContext';
import { withTranslation } from 'react-i18next';
import { format, parseISO, formatDistanceStrict, isValid } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import DocumentReference from './DocumentReference';
import ImagingStudy from './ImagingStudy';
import Media from './Media';
import Button from '@material-ui/core/Button';
import { TailSpin } from 'react-loader-spinner';
import { getOwnOrganization } from '../utils/getOwn';
import { getExternalServerUrl, getExternalServiceRequestId } from '../utils/checkExternalReference';

/**
 * Wraps everything into `FhirClientProvider` so that any component
 * can have access to the fhir client through the context.
 */
class Communication extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.state = {
      sentDate: '',
      message: '',
      media: [],
      docRef: [],
      imgStudies: [],
      sender: '',
      status: '',
      statusText: '',
      classes: '',
      draft: true,
      startTime: '',
      endTime: '',
      isCall: false,
      isVideoconf: false,
      isVideoconfActive: false,
      language: localStorage.getItem('i18nextLng') ?? 'en',
      loading: true,
      externalServerUrl: null,
    };
  }

  loadData = async () => {
    const client = this.context.client;

    client
      .request(`ServiceRequest/${this.props.sr_id}`, {
        pageLimit: 0,
        flat: true,
        resolveReferences: ['requester'],
      })
      .then(async srData => {
        this.setState({ externalServerUrl: getExternalServerUrl(srData, client) });
        await client
          .request(
            { url: `Communication/${this.props.id}`, federatedServerUrl: getExternalServerUrl(srData, client) },
            {
              pageLimit: 0,
              flat: true,
              resolveReferences: ['sender'],
            }
          )
          .then(async data => {
            let draft = client.getPath(data, 'status') == 'preparation' ? true : false;

            // calculate status
            let classes = 'status draft';
            let status = 'Draft';
            let statusText = 'Draft';

            // com is not read
            if (client.getPath(data, 'received') == undefined && client.getPath(data, 'status') != 'preparation') {
              classes = 'status notread';
              status = 'MessageNotSeen';
              statusText = '';
            }

            // com is read
            if (client.getPath(data, 'received') != undefined && client.getPath(data, 'status') == 'completed') {
              classes = 'status read';
              status = 'MessageSeen';
              statusText = '';
            }

            // com is a phone call
            if (
              client.getPath(data, 'topic.coding.0.code') == 'PHONE-CONSULT' &&
              client.getPath(data, 'status') == 'completed'
            ) {
              if (client.getPath(data, 'payload.1.contentString')) {
                const durationPayload = JSON.parse(client.getPath(data, 'payload.1.contentString'));
                if (durationPayload.start_time && durationPayload.end_time) {
                  const isValidDate = isValid(
                    parseISO(durationPayload.start_time, "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX", new Date())
                  );

                  let start;
                  let end;
                  if (!isValidDate) {
                    start = new Date((Math.floor(durationPayload.start_time) + 978307200) * 1000); // convert from Apple Core Data Timestamp that counts from 1.1.2001
                    end = new Date((Math.floor(durationPayload.end_time) + 978307200) * 1000); // convert from Apple Core Data Timestamp that counts from 1.1.2001
                  } else {
                    start = new Date(durationPayload.start_time);
                    end = new Date(durationPayload.end_time);
                  }

                  this.setState({ startTime: start, endTime: end, isCall: true });
                }
              }
              classes = 'status call';
              status = 'Call';
              statusText = 'Call';
            }

            // com is a videoconference
            if (client.getPath(data, 'topic.coding.0.code') == 'VIDEOCONFERENCE') {
              if (client.getPath(data, 'status') == 'in-progress') {
                this.props.setVideoconferenceActive(true);
                this.props.setVideoconferenceLink(client.getPath(data, 'payload.0.contentString'));
                this.setState({ isVideoconf: true, isVideoconfActive: true });
              } else {
                if (client.getPath(data, 'payload.1.contentString')) {
                  const durationPayload = JSON.parse(client.getPath(data, 'payload.1.contentString'));
                  if (durationPayload.start_time && durationPayload.end_time) {
                    const start = new Date(durationPayload.start_time);
                    const end = new Date(durationPayload.end_time);
                    this.setState({ startTime: start, endTime: end });
                  }
                }
                this.setState({ isVideoconf: true });
              }
              classes = 'status call';
              status = 'Call';
              statusText = 'Videoconference';
            }

            // com has content
            let documentReferences = [];
            let imagingStudies = [];
            if (client.getPath(data, 'payload') !== undefined) {
              client.getPath(data, 'payload').forEach(ref => {
                // com contains a DocumentReferences
                if (
                  ref.contentReference !== undefined &&
                  ref.contentReference.reference.startsWith('DocumentReference')
                ) {
                  documentReferences.push(ref.contentReference.reference);
                }
                // com contains an ImagingStudy
                if (ref.contentReference !== undefined && ref.contentReference.reference.startsWith('ImagingStudy')) {
                  imagingStudies.push(ref.contentReference.reference);
                }
              });
              this.setState({
                docRef: documentReferences,
                imgStudies: imagingStudies,
              });
            }

            // get sender of com
            let senderId = '';
            let sender = '';
            if (data.sender) {
              if (data.sender.resourceType === 'Organization') {
                senderId = data.sender.id;
                sender = data.sender.name;
              } else if (data.sender.resourceType === 'PractitionerRole') {
                await client
                  .request(`PractitionerRole?practitioner=${data.sender.practitioner.reference}`, {
                    pageLimit: 0,
                    flat: true,
                    resolveReferences: ['organization'],
                  })
                  .then(organizationData => {
                    senderId = organizationData[0].organization.id;
                    sender = organizationData[0].organization.name;
                  });
              }
            }

            // is requester of the ServiceRequest the sender?
            if (
              'Organization/' + senderId ==
              (srData.requester.resourceType === 'Organization'
                ? srData.requester.resourceType + '/' + srData.requester.id
                : srData.requester.organization.reference)
            ) {
              classes += ' right';
            } else {
              classes += ' left';
            }

            this.setState({
              sentDate: format(parseISO(client.getPath(data, 'sent')), 'P   p', { locale: de }),
              message: client.getPath(data, 'note.0.text'),
              sender: sender,
              classes: classes,
              status: status,
              statusText: statusText,
              draft: draft,
            });

            await client
              .request(
                {
                  url: `Media?based-on=ServiceRequest/${
                    getExternalServiceRequestId(srData, client) ?? this.props.sr_id
                  }&_summary=true`,
                  federatedServerUrl: getExternalServerUrl(srData, client),
                },
                {
                  pageLimit: 0,
                  flat: true,
                }
              )
              .then(data => {
                data.forEach(o => {
                  // TODO können auch mehrere Referenzen im partOf stehen?
                  if (client.getPath(o, 'partOf.0.reference') == 'Communication/' + this.props.id) {
                    this.setState({
                      media: [...this.state.media, client.getPath(o, 'id')],
                    });
                  }
                });
              })
              .catch((error) => {
                if (error.status == 401) {
                  console.log('Permission denied! No access or no Media ressources present.');
                }
              });

            // com has a diagnostic report
            // TODO what if partOf holds multiple references?
            if (
              (client.getPath(data, 'status') == 'not-done' || client.getPath(data, 'status') == 'completed') &&
              client.getPath(data, 'partOf.0.reference') !== undefined &&
              client.getPath(data, 'partOf.0.reference').startsWith('DiagnosticReport')
            ) {
              // read DiagnosticReport
              await client
                .request(
                  {
                    url: client.getPath(data, 'partOf.0.reference'),
                    federatedServerUrl: getExternalServerUrl(srData, client),
                  },
                  {
                    pageLimit: 0,
                    flat: true,
                  }
                )
                .then(dr_data => {
                  // com has unconfirmed diagnostic report
                  let classes;
                  if (client.getPath(data, 'sender.id') == this.props.srRequester) {
                    classes = 'right ';
                  } else {
                    classes = 'left ';
                  }

                  if (client.getPath(dr_data, 'status') == 'partial') {
                    classes += 'status unconfirmed_report';
                    this.setState({
                      classes: classes,
                      status: 'ReportUnconfirmed',
                      statusText: 'DiagnosticReport',
                      message: client.getPath(dr_data, 'conclusion'),
                    });
                  }

                  // com has confirmed diagnostic report
                  if (client.getPath(dr_data, 'status') == 'final') {
                    classes += 'status confirmed_report';
                    this.setState({
                      classes: classes,
                      status: 'ReportConfirmed',
                      statusText: 'DiagnosticReport',
                      message: client.getPath(dr_data, 'conclusion'),
                    });
                  }
                });
            }

            // mark com as read if applicable
            if (
              'Organization/' + senderId !== (await getOwnOrganization(client)) &&
              data.received == undefined &&
              data.status != 'preparation' &&
              client.getPath(data, 'topic.coding.0.code') == undefined
            ) {
              client
                .patch(
                  `Communication/${data.id}`,
                  [
                    {
                      op: 'replace',
                      path: '/status',
                      value: 'completed',
                    },
                    {
                      op: 'add',
                      path: '/received',
                      value: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX"),
                    },
                  ],
                  {
                    url: `Communication/${data.id}`,
                    federatedServerUrl: getExternalServerUrl(srData, client),
                  }
                )
                .then(patchedData => {
                  if (patchedData.received != undefined && patchedData.status == 'completed') {
                    this.setState({
                      classes: 'status read',
                      status: 'MessageSeen',
                      statusText: '',
                    });
                  }
                });
            }
          });
      });
  };

  onEndVideoconferenceButtonClick = () => {
    const client = this.context.client;
    client.request('ServiceRequest' + this.props.sr_id, { pageLimit: 0, flat: true }).then(sr => {
      client
        .request(
          { url: 'Communication/' + this.props.id, federatedServerUrl: getExternalServerUrl(sr, client) },
          {
            pageLimit: 0,
            flat: true,
          }
        )
        .then(com => {
          // set new status for Communication
          com.status = 'completed';
          com.payload[1].contentString = JSON.stringify({
            ...JSON.parse(com.payload[1].contentString),
            end_time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSXXX"),
          });
          client
            .update(com, {
              url: getExternalServerUrl(sr, client),
              federatedServerUrl: getExternalServerUrl(sr, client),
            })
            .then(updatedCom => {
              const durationPayload = JSON.parse(updatedCom.payload[1].contentString);
              if (durationPayload.start_time && durationPayload.end_time) {
                const start = new Date(durationPayload.start_time);
                const end = new Date(durationPayload.end_time);
                this.setState({ startTime: start, endTime: end, isVideoconfActive: false });
                this.props.setVideoconferenceActive(false);
              }
            });
        });
    });
  };

  componentWillUnmount() {}

  async componentDidMount() {
    await this.loadData();
    this.setState({ language: localStorage.getItem('i18nextLng') ?? 'en' });
    document.addEventListener('langChange', e => {
      this.setState({ language: e.detail });
    });
    this.setState({ loading: false });
  }

  render() {
    const { t } = this.props;
    const stateMessage = this.state.draft ? t('Draft') : t(this.state.status);

    const videoconfButtons = this.state.isVideoconfActive ? (
      <div>
        <Button variant="contained" color="primary" onClick={this.props.onJoinVideoconferenceButtonClick}>
          {t('Join Videoconference')}
        </Button>
        <Button variant="contained" color="primary" onClick={this.onEndVideoconferenceButtonClick}>
          {t('End Videoconference')}
        </Button>
      </div>
    ) : null;

    const duration = this.state.isVideoconf ? (
      <div id="callDuration">
        {t('Call duration')}:{' '}
        {this.state.startTime && this.state.endTime
          ? formatDistanceStrict(this.state.startTime, this.state.endTime, {
              locale: enUS.code.includes(this.state.language) ? enUS : de,
            })
          : '-'}
      </div>
    ) : null;

    return this.state.loading ? (
      <div className={this.state.classes}>
        <div style={{ padding: 10, alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
          <TailSpin ariaLabel="loading-indicator" color="#21264e" height="20" width="20" />
        </div>
      </div>
    ) : (
      <div className={this.state.classes}>
        <div className="communicationHeader">
          <div className="communicationSentDate">{this.state.sentDate}</div>
          <div className="communicationStatusText">{t(this.state.statusText)}</div>
          <div className="communicationSender">{this.state.sender}</div>
        </div>

        <div className="mediaContainer">
          {this.state.media.length !== 0 ? (
            <Media ids={this.state.media} externalServerUrl={this.state.externalServerUrl} />
          ) : null}
          {this.state.docRef.length !== 0 ? (
            <DocumentReference ids={this.state.docRef} externalServerUrl={this.state.externalServerUrl} sr_id={this.props.sr_id} />
          ) : null}
          {this.state.imgStudies.length !== 0 ? (
            <ImagingStudy
              ids={this.state.imgStudies}
              externalServerUrl={this.state.externalServerUrl}
              sr_id={this.props.sr_id}
            />
          ) : null}
        </div>

        {videoconfButtons}
        {duration}

        <div className="communicationMessage">{this.state.message}</div>
        <div className="communicationFooter">
          <div className={'communicationLabel ' + this.state.classes + ' ' + this.state.language}>{stateMessage}</div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Communication);
